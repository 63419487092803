<template>
  <div class="add-organization" style="margin-top: 50px">
    <h1>Add Organization</h1>
    <form @submit.prevent="addOrganization">
      <div class="form-group">
        <label for="name">Name:</label>
        <input
          type="text"
          id="name"
          v-model="form.name"
          class="form-control"
          required
        />
      </div>
      <div class="form-group">
        <label for="image">Image URL:</label>
        <input
          type="text"
          id="image"
          v-model="form.imageUrl"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="file">Upload Image:</label>
        <input
          type="file"
          id="file"
          @change="handleFileUpload"
          class="form-control-file"
        />
      </div>
      <div class="form-group">
        <label for="contact">Contact:</label>
        <input
          type="text"
          id="contact"
          v-model="form.contact"
          class="form-control"
          required
        />
      </div>
      <div class="form-group">
        <label for="address">Address:</label>
        <input
          type="text"
          id="address"
          v-model="form.address"
          class="form-control"
          required
        />
      </div>
      <div class="button-group">
        <button type="button" class="btn btn-secondary" @click="cancelAdd">
          Cancel
        </button>
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        imageUrl: "",
        contact: "",
        address: "",
        file: null,
      },
      errorMessage: "",
    };
  },
  methods: {
    handleFileUpload(event) {
      this.form.file = event.target.files[0];
    },
    cancelAdd() {
      this.$router.push({ name: "ListOrganization" });
    },
    addOrganization() {
      if (this.form.file) {
        const formData = new FormData();
        formData.append("file", this.form.file);

        this.$axios
          .post("files/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.form.imageUrl = response.data.location;
            this.saveOrganization();
          })
          .catch((error) => {
            this.errorMessage = "Error uploading image: " + error.message;
          });
      } else {
        this.saveOrganization();
      }
    },
    saveOrganization() {
      this.$axios
        .post("/organizations", {
          name: this.form.name,
          imageUrl: this.form.imageUrl,
          contact: this.form.contact,
          address: this.form.address,
        })
        .then(() => {
          this.$router.push({ name: "ListOrganization" })
          .then(() => {
            setTimeout(() => {
              return alert("You have been logged out.");
            }, 300); // Delay of 100ms
          });
        })
        .catch((error) => {
          this.errorMessage = "Error adding organization: " + error.message;
        });
    },
  },
};
</script>

<style scoped>
.add-organization {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.error {
  color: red;
}
</style>
