<template>
  <div class="edit-assets-organization" style="margin-top: 50px;">
    <h1>Edit Assets Organization</h1>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="organizationId">Organization</label>
        <select v-model="form.organizationId" class="form-control" id="organizationId" enabled>
          <option v-for="org in organizations" :value="org._id" :key="org._id">{{ org.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="contractAddress">Contract Address:</label>
        <input type="text" v-model="form.contractAddress" required class="form-control" />
      </div>
      <div class="form-group">
        <label for="creatorAddress">Creator Address:</label>
        <input type="text" v-model="form.creatorAddress" required class="form-control" />
      </div>
      <div class="form-group">
        <label for="contractType">Contract Type:</label>
        <input type="text" v-model="form.contractType" required class="form-control" />
      </div>
      <div class="button-group">
        <button type="button" class="btn btn-secondary" @click="cancelEdit">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </form>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        organizationId: "",
        contractAddress: "",
        creatorAddress: "",
        contractType: "",
      },
      organizations: [],
      errorMessage: "",
    };
  },
  mounted() {
    this.fetchOrganizations();
    this.fetchAssetOrganization();
  },
  methods: {
    cancelEdit() {
      this.$router.push({ name: "ListAssetsOrganization" });
    },
    fetchOrganizations() {
      this.$axios.get('/organizations')
        .then(response => {
          this.organizations = response.data.map(org => ({
            _id: org._id,
            name: org.name,
          }));
        })
        .catch(error => {
          alert('Error fetching organizations: ' + error.message);
        });
    },
    fetchAssetOrganization() {
      const assetId = this.$route.params.id;
      if (!assetId) {
        this.errorMessage = "Missing asset ID";
        return;
      }
      this.$axios
        .get(`/organizations-assets/${assetId}`)
        .then((response) => {
          this.form = response.data;
        })
        .catch((error) => {
          this.errorMessage = "Failed to fetch organization asset: " + error.message;
        });
    },
    submitForm() {
      this.errorMessage = "";
      if (!this.validateForm()) {
        return;
      }
      this.$axios
        .put(`/organizations-assets/${this.$route.params.id}`, this.form)
        .then(() => {
          this.$router.push({ name: "ListAssetsOrganization" })
          .then(() => {
            setTimeout(() => {
              return alert("Succeed edit data.");
            }, 300);
          })
        })
        .catch((error) => {
          this.errorMessage = "Failed to update organization asset: " + error.message;
        });
    },
    validateForm() {
      if (!this.form.contractAddress || !this.form.creatorAddress || !this.form.contractType) {
        this.errorMessage = "Please fill in all fields.";
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.edit-assets-organization {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.error {
  color: red;
}
</style>
