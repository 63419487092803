<template>
  <div class="organization-list" style="margin-top: 50px;">
    <h1>Organization List</h1>
    <button class="btn btn-primary mb-3" @click="routeToAddOrganization">
      Add Organization
    </button>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No.</th>
            <th>ID</th>
            <th>Name</th>
            <th>Image</th>
            <th>Contact</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(org, index) in organizations" :key="org.id">
            <td>{{ index + 1 }}</td>
            <td>{{ org.id }}</td>
            <td>{{ org.name }}</td>
            <td>
              <img :src="org.imageUrl" alt="Organization pict" class="img-fluid" style="max-width: 50px; height: auto;" />
            </td>
            <td>{{ org.contact }}</td>
            <td>{{ org.address }}</td>
            <td>
              <button class="btn btn-secondary btn-sm" @click="routeToEditOrganization(org.id)">Edit</button>
              <button class="btn btn-danger btn-sm" @click="confirmDelete(org.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      organizations: [],
      errorMessage: "",
    };
  },
  mounted() {
    this.fetchOrganizations();
  },
  methods: {
    fetchOrganizations() {
      this.$axios.get('/organizations')
        .then(response => {
          this.organizations = response.data.map(org => ({
            id: org._id,
            name: org.name,
            imageUrl: org.imageUrl,
            contact: org.contact,
            address: org.address,
          }));
        })
        .catch(error => {
          this.errorMessage = "Failed to fetch organizations: " + error.message;
        });
    },
    routeToAddOrganization() {
      this.$router.push({ name: 'AddOrganization' });
    },
    routeToEditOrganization(id) {
      this.$router.push({ name: 'EditOrganization', params: { id } });
    },
    confirmDelete(id) {
      if (confirm("Are you sure you want to delete this organization?")) {
        this.deleteOrganization(id);
      }
    },
    deleteOrganization(id) {
      this.$axios.delete(`/organizations/${id}`)
        .then(() => {
          this.fetchOrganizations();
          alert("Succeed Delete Data");
        })
        .catch(error => {
          this.errorMessage = "Failed to delete organization: " + error.message;
        });
    },
  },
};
</script>

<style scoped>
.list-assets-organization {
  padding: 20px;
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
}

.table-responsive {
  overflow-x: auto;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table thead th {
  background-color: #f8f9fa;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.btn {
  margin-right: 5px;
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .list-assets-organization {
    padding: 10px;
  }

  .table th,
  .table td {
    padding: 0.5rem;
  }
}
</style>
