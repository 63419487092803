<template>
  <div class="list-assets-organization">
    <h1>Assets Organization List</h1>
    <button class="btn btn-primary mb-3" @click="routeToAddAssetsOrganization">
      Add Assets Organization
    </button>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Organization Name</th>
            <th>Contract Address</th>
            <th>Contract Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(asset, index) in assets" :key="asset._id">
            <td>{{ index + 1 }}</td>
            <td>{{ asset.organizationName }}</td>
            <td>{{ asset.contractAddress }}</td>
            <td>{{ asset.contractType }}</td>
            <td>
              <router-link :to="{ name: 'EditAssetsOrganization', params: { id: asset._id } }" class="btn btn-secondary btn-sm">Edit</router-link>
              <button @click="confirmDelete(asset.organizationName)" class="btn btn-danger btn-sm">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assets: [],
      organizations: [],
      errorMessage: "",
    };
  },
  mounted() {
    this.fetchAssets();
    this.fetchOrganizations();
  },
  methods: {
    routeToAddAssetsOrganization() {
      this.$router.push({ name: 'AddAssetsOrganization' });
    },
    fetchAssets() {
      this.$axios
        .get('/organizations-assets')
        .then((response) => {
          this.assets = response.data;
        })
        .catch((error) => {
          this.errorMessage = "Failed to fetch assets: " + error.message;
        });
    },
    fetchOrganizations() {
      this.$axios.get('/organizations')
        .then(response => {
          this.organizations = response.data;
        })
        .catch(error => {
          this.errorMessage = 'Error fetching organizations: ' + error.message;
        });
    },
    confirmDelete(organizationName) {
      const organization = this.organizations.find(org => org.name === organizationName);
      if (organization) {
        if (confirm(`Are you sure you want to delete this asset?\nOrganization: ${organizationName}`)) {
          this.deleteAsset(organization._id);
        }
      } else {
        this.errorMessage = "Organization not found.";
      }
    },
    deleteAsset(organizationId) {
      this.$axios
        .delete(`/organizations-assets/${organizationId}`)
        .then(() => {
          this.fetchAssets();
          alert("Succeed Delete Data.");
        })
        .catch((error) => {
          this.errorMessage = error.response && error.response.status === 404
            ? "Asset not found."
            : "Failed to delete asset: " + error.message;
        });
    },
  },
};
</script>

<style scoped>
.list-assets-organization {
  padding: 20px;
  margin-top: 50px;
  width: 100%;
  box-sizing: border-box;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table thead th {
  background-color: #f8f9fa;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #f2f2f2;
}

.btn {
  margin-right: 5px;
}

.error {
  color: red;
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .list-assets-organization {
    padding: 10px;
  }

  .table th,
  .table td {
    padding: 0.5rem;
  }
}
</style>
