<template>
  <div class="home">
    <div class="content">
      <img alt="Vue logo" src="../assets/img/Group 522.png">  
      <h1>Hai...there</h1>
      <p>
        Welcome to our application! Our mission is to provide the best user
        experience. We are constantly improving and updating our services to
        meet the needs of our users.
      </p>
      <p>
        This app is built using the latest web technologies including
        <b>Vue.js, Vue Router, Vuex, and Axios</b>.
      </p>
      <p>
        We hope you enjoy using our application. If you have any feedback, feel
        free to reach out to us.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  computed: {
    ...mapGetters(['user']),
  },
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 56px); /* Adjust for navbar height */
  padding: 20px; /* Added padding for better spacing */
}

.content {
  text-align: center;
  max-width: 600px;
  padding: 20px;
  background-color: #fff; /* Added a white background for better readability */
  border-radius: 8px; /* Rounded corners for the content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

img {
  width: 150px;
  margin-bottom: 20px;
}

h1, p {
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
}
</style>
