<template>
  <div class="profile-edit">
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="12" md="6">
          <b-card class="mb-3">
            <b-form @submit.prevent="editUser">
              <b-form-group label="Name:" label-for="name">
                <b-form-input
                  id="name"
                  v-model="user.name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Address:" label-for="address">
                <b-form-input
                  id="address"
                  v-model="user.address"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Upload Image:" label-for="file">
                <b-form-file id="file" @change="handleFileUpload"></b-form-file>
              </b-form-group>
              <b-form-group label="Or Enter Image URL:" label-for="imageUrl">
                <b-form-input
                  id="imageUrl"
                  v-model="user.imageUrl"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Contact:" label-for="contact">
                <b-form-input
                  id="contact"
                  v-model="user.contact"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Description:" label-for="description">
                <b-form-input
                  id="description"
                  v-model="user.description"
                  required
                ></b-form-input>
              </b-form-group>
              <b-button type="submit" variant="primary">Update</b-button>
              <b-button @click="goBack" variant="secondary">Cancel</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ProfileEdit",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user: {
        name: '',
        address: '',
        imageUrl: '', // Initialize imageUrl for image URL storage
        contact: '',
        description: ''
      },
      file: null, // Variable to store the selected file
    };
  },
  methods: {
    fetchDetailUser() {
      this.$axios.get(`/organizations/${this.id}`)
        .then((response) => {
          this.user = response.data; // Assign fetched user data to 'user' object
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0]; // Store the selected file
    },
    editUser() {
      if (this.file) {
        // If a file is selected, upload it first
        const formData = new FormData();
        formData.append('file', this.file);

        this.$axios.post('files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          // Update imageUrl with the uploaded image URL
          this.user.imageUrl = response.data.location;
          this.saveUser();
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
        });
      } else {
        // If no file is selected, directly save user data
        this.saveUser();
      }
    },
    saveUser() {
      // Prepare user data to be saved
      const userData = {
        name: this.user.name,
        address: this.user.address,
        imageUrl: this.user.imageUrl,
        contact: this.user.contact,
        description: this.user.description,
      };

      // Send PUT request to update user data
      const token = this.$store.state.token;
      this.$axios.put(`/organizations/${this.id}`, userData, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(() => {
          console.log("User updated successfully");
          this.$router.push({ name: 'profile' }); // Redirect to profile view after update
        })
        .catch((error) => {
          console.error("Error updating user:", error);
        });
    },
    goBack() {
      this.$router.push({ name: 'profile' }); // Navigate back to profile view
    },
  },
  mounted() {
    this.fetchDetailUser(); // Fetch user details when the component is mounted
  },
};
</script>

<style scoped>
.profile-edit {
  text-align: center;
  margin: 20px;
}
</style>
