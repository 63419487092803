<template>
  <div :class="['sidebar', { 'sidebar_on': toggleSwitch }]" id="toggle_sidebar">
    <div class="toggle" @click="setToggle">
      <i :class="toggleSwitch ? 'mdi mdi-menu' : 'mdi mdi-menu'"></i>
    </div>
    <!-- Navbar Brand and Logo -->
    <div class="navbar-brand">
      <a href="#">
        <img src="@/assets/img/Group 522.png" alt="Logo" class="navbar-logo">
      </a>
    </div>
    <!-- End Navbar Brand and Logo -->

    <ul class="nav flex-column mt-4">
      <router-link to="/home" class="nav-link" exact>Home</router-link>
      <router-link to="/about" class="nav-link">About</router-link>
      <li class="nav-item">
        <a class="nav-link" @click="toggleDropdown('organization')">
          Organizations 
          <i :class="dropdowns.organization ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'"></i>
        </a>
        <ul v-if="dropdowns.organization" class="sidebar-item-dropdown">
          <li>
            <router-link to="/listOrganizations" class="dropdown-item">List Organizations</router-link>
          </li>
          <li>
            <router-link to="/listAssetsOrganizations" class="dropdown-item">Assets Organizations</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    toggleSwitch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dropdowns: {
        organization: false,
      },
    };
  },
  watch: {
    $route(to) {
      if (this.isAuthenticated && (to.path === '/listOrganizations' || to.path === '/listAssetsOrganizations')) {
        this.dropdowns.organization = true;
      }
    }
  },
  mounted() {
    if (this.isAuthenticated && (this.$route.path === '/listOrganizations' || this.$route.path === '/listAssetsOrganizations')) {
      this.dropdowns.organization = true;
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    setToggle() {
      this.$emit('toggle-sidebar');
    },
    toggleDropdown(name) {
      this.dropdowns[name] = !this.dropdowns[name];
    },
  },
};
</script>

<style scoped lang="scss">
@import url('https://cdn.materialdesignicons.com/5.4.55/css/materialdesignicons.min.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.sidebar {
  position: fixed;
  z-index: 1001;
  left: -180px;
  top: 0;
  width: 180px;
  height: 100vh;
  background-color: #007bff;
  transition: left 0.3s ease-in-out;
  padding-top: 60px; /* Add padding to avoid overlap with navbar */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  &.sidebar_on {
    left: 0;
  }

  .nav-link {
    margin-top: 0.6rem;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    &:hover {
      color: #495057;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
    }
  }

  .toggle {
    position: absolute;
    right: -30px;
    top: 0px; /* Adjust position to align with logo */
    width: 30px;
    height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    border-radius: 0 5px 5px 0;
    color: #fff;
    i {
      font-size: 24px;
    }
  }

  .sidebar-item-dropdown {
    background-color: #007bff;
    list-style: none;
    padding-left: 0;
    .dropdown-item {
      padding: 10px 15px;
      color: #fff;
      text-decoration: none;
      display: block;
      &:hover {
        color: #495057;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
      }
    }
  }

  /* Navbar Brand and Logo Styles */
  .navbar-brand {
    display: flex;
    align-items: center;
    margin: -35px 0 0 15px;
  }

  .navbar-logo {
    width: 50px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    left: -100%;
    &.sidebar_on {
      left: 0;
    }
  }
  .toggle {
    right: 0;
  }
}
</style>
