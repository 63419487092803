import { createApp } from 'vue';
import App from './App.vue';
// import gAuthPlugin from 'vue3-google-oauth2';
import axios from 'axios';
import router from './router';
import store from './store';

import { createBootstrap } from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@/assets/scss/style.scss';
import '@mdi/font/css/materialdesignicons.css';

// let gauthClientId = "988937354702-uef6nf84tqrtf43aa08fkuh1o8r7793u.apps.googleusercontent.com";

// Set base URL for Axios
axios.defaults.baseURL = 'https://payrollium.b2camp.id';

// Intercept requests to include authorization token if available
axios.interceptors.request.use(
  (config) => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

store.dispatch('checkAuth');

// Initialize Vue app
const app = createApp(App);

// Make axios accessible globally
app.config.globalProperties.$axios = axios; 

// Use plugins and mount app
// app.use(gAuthPlugin, {
//   clientId: gauthClientId,
//   scope: 'email', 
//   prompt: 'consent', 
//   fetch_basic_profile: false
// });

app.use(store);
app.use(router);
app.use(createBootstrap({ components: true, directives: true }));
app.mount('#app');
