// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProfileEdit from '../views/Profile/ProfileEditView.vue';
import ListOrganization from '@/views/Organization/ListOrganization.vue'
import AddOrganization from '@/views/Organization/AddOrganization.vue'
import EditOrganization from '@/views/Organization/EditOrganization.vue'
import ListAssetsOrganization from '@/views/AssetsOrganization/ListAssetsOrganization.vue'
import AddAssetsOrganization from '@/views/AssetsOrganization/AddAssetsOrganization.vue'
import EditAssetsOrganization from '@/views/AssetsOrganization/EditAssetsOrganization.vue'
import store from '../store'; // Import your Vuex store

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile/ProfileView.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/profile-edit/:id',
    name: 'ProfileEdit',
    component: ProfileEdit,
    props: true, // This allows the id parameter to be passed as a prop
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/listOrganizations',
    name: 'ListOrganization',
    component: ListOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/listOrganizations/add',
    name: 'AddOrganization',
    component: AddOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/listOrganizations/edit/:id',
    name: 'EditOrganization',
    component: EditOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/listAssetsOrganizations',
    name: 'ListAssetsOrganization',
    component: ListAssetsOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/ListAssetsOrganizations/add',
    name: 'AddAssetsOrganization',
    component: AddAssetsOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/ListAssetsOrganizations/edit/:id',
    name: 'EditAssetsOrganization',
    component: EditAssetsOrganization,
    meta: {
      requireAuth: true,
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch('checkAuth'); // Check auth state on each route change

  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ path: '/home' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
