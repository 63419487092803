<template>
  <div class="edit-organization" style="margin-top: 50px;">
    <h1>Edit Organization {{ id }}</h1>
    <form @submit.prevent="editOrganization">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="name" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="image">Image URL:</label>
        <input type="text" id="image" v-model="image" class="form-control" />
      </div>
      <div class="form-group">
        <label for="file">Upload Image:</label>
        <input type="file" id="file" @change="handleFileUpload" class="form-control-file" />
      </div>
      <div class="form-group">
        <label for="contact">Contact:</label>
        <input type="text" id="contact" v-model="contact" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="address">Address:</label>
        <input type="text" id="address" v-model="address" class="form-control" required />
      </div>
      <div class="button-group">
        <button type="button" class="btn btn-secondary" @click="cancelEdit">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      name: '',
      image: '',
      contact: '',
      address: '',
      file: null,
    };
  },
  mounted() {
    this.fetchOrganization();
  },
  methods: {
    fetchOrganization() {
      this.$axios.get(`/organizations/${this.id}`)
        .then((res) => {
          this.name = res.data.name;
          this.image = res.data.imageUrl;
          this.contact = res.data.contact;
          this.address = res.data.address;
        })
        .catch((error) => {
          alert("Error fetching organization: " + error.message);
        });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    cancelEdit() {
      this.$router.push({ name: 'ListOrganization' });
    },
    editOrganization() {
      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);

        this.$axios.post('files/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.image = response.data.location;
          this.saveOrganization();
        })
        .catch((error) => {
          if (error.response) {
            alert(`Error uploading image: ${error.response.status} ${error.response.statusText}`);
          } else if (error.request) {
            alert("No response received from server.");
          } else {
            alert("Error setting up request: " + error.message);
          }
        });
      } else {
        this.saveOrganization();
      }
    },
    saveOrganization() {
      this.$axios.put(`/organizations/${this.id}`, {
        name: this.name,
        imageUrl: this.image,
        contact: this.contact,
        address: this.address,
      })
      .then(() => {
        this.$router.push({ name: 'ListOrganization' })
        .then(() => {
          setTimeout(() => {
            return alert("succeed edit data");
          }, 300);          
        })        
      })
      .catch((error) => {
        alert("Error updating organization: " + error.message);
      });
    },
  },
};
</script>

<style scoped>
.edit-organization {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.error {
  color: red;
}
</style>
