<template>
  <nav class="navbar">
    <button class="navbar-toggler" @click="toggleMenu">
      <span class="navbar-toggler-icon">&#9776;</span>
    </button>
    <div :class="['navbar-collapse', { show: isMenuOpen }]">
      <ul class="navbar-nav ml-auto">
        <li v-if="user.name" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" @click="toggleDropdown">{{ user.name }}</a>
          <ul class="dropdown-menu" :class="{ show: isDropdownOpen }">
            <li class="dropdown-item" @click="viewProfile">Profile</li>
            <li class="dropdown-item" @click="confirmLogout">Logout</li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Nav",
  data() {
    return {
      user: {
        name: ''
      },
      isMenuOpen: false,
      isDropdownOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    ...mapActions(["logout"]),
    confirmLogout() {
      if (confirm("Are you sure you want to logout?")) {
        this.handleLogout();
      }
    },
    handleLogout() {
      this.logout();
      this.$router.push("/")
      .then(() => {
        setTimeout(() => {          
            return alert("You have been logged out.");
          }, 300); // Delay of 100ms
      })
    },
    getUserData() {
      const token = this.$store.state.token;
      this.$axios
        .get("/organizations", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((profileResponse) => {
          const users = profileResponse.data;
          const userId = users[0]?._id;

          if (!userId) {
            console.error("User ID not found in the array.");
            return;
          }

          this.$axios
            .get(`/organizations/${userId}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              this.user = res.data;
            })
            .catch((error) => {
              console.error("Error fetching user data: ", error);
            });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.errorMessage = "Unauthorized access - please log in again.";
            this.logout();
          } else {
            console.error(
              "Profile fetch failed:",
              error.response ? error.response.data.message : error.message
            );
          }
        });
    },
    viewProfile() {
      this.$router.push({ name: "profile" });
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style scoped>
.navbar {
  position: fixed; /* Navbar tetap di atas */
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  padding: 10px 5px;
  height: 60px;
  box-sizing: border-box;
}

.navbar-toggler {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

.navbar-toggler-icon {
  display: inline-block;
}

.navbar-collapse {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  transition: height 0.3s ease;
  height: auto;
}

.navbar-collapse.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #007bff;
  width: 100%;
  padding: 10px 0;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
}

.nav-link:hover {
  padding: 5px auto;
}

@media (max-width: 768px) {
  .navbar-toggler {
    display: block;
  }
  
  .navbar-collapse {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: #007bff;
    width: 100%;
  }

  .navbar-collapse.show {
    display: flex;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    width: 100%;
    text-align: left;
    padding: 10px 20px;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
  }
}
</style>
