<template>
  <div class="add-assets-organization" style="margin-top: 50px;">
    <h1>Add Organization Asset</h1>
    <form @submit.prevent="addAssetsOrganization">
      <div class="form-group">
        <label for="organizationId">Organization</label>
        <select v-model="form.organizationId" class="form-control" id="organizationId" required>
          <option v-for="org in organizations" :value="org._id" :key="org._id">{{ org.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="contractAddress">Contract Address</label>
        <input type="text" v-model="form.contractAddress" class="form-control" id="contractAddress" required />
      </div>
      <div class="form-group">
        <label for="creatorAddress">Creator Address</label>
        <input type="text" v-model="form.creatorAddress" class="form-control" id="creatorAddress" required />
      </div>
      <div class="form-group">
        <label for="contractType">Contract Type</label>
        <input type="text" v-model="form.contractType" class="form-control" id="contractType" required />
      </div>
      <div class="button-group">
        <button type="button" class="btn btn-secondary" @click="cancelAdd">Cancel</button>
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        organizationId: "",
        contractAddress: "",
        creatorAddress: "",
        contractType: ""
      },
      organizations: [],
      errorMessage: ""
    };
  },
  mounted() {
    this.fetchOrganizations();
    // Assuming you have a method to fetch user data from cookies or another source
  },
  methods: {
    fetchOrganizations() {
      this.$axios.get('/organizations')
        .then(response => {
          this.organizations = response.data.map(org => ({
            _id: org._id,
            name: org.name
          }));
        })
        .catch(error => {
          this.errorMessage = 'Error fetching organizations: ' + error.message;
        });
    },
    cancelAdd() {
      this.$router.push({ name: "ListAssetsOrganization" });
    },
    addAssetsOrganization() {
      this.$axios
        .post("/organizations-assets", this.form)
        .then(() => {
          this.$router.push({ name: "ListAssetsOrganization" })
          .then(() => {
            setTimeout(() => {
              alert("Succeed add data.");
            }, 300);
          })
        })
        .catch((error) => {
          this.errorMessage = "Error adding organization asset: " + error.message;
        });
    }
  }
};
</script>

<style scoped>
.add-assets-organization {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.error {
  color: red;
}
</style>
