<template>
  <div id="app">
    <SidebarColumn v-if="isAuthenticated" @toggle-sidebar="handleSidebarToggle" :toggleSwitch="isSidebarOpen" />
    <div :class="['main-content', { 'sidebar-open': isAuthenticated && isSidebarOpen }]">
      <Nav v-if="isAuthenticated" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Nav from './components/Nav.vue';
import SidebarColumn from './components/SidebarColumn.vue';

export default {
  name: 'App',
  components: {
    Nav,
    SidebarColumn
  },
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue) {
        this.isSidebarOpen = true;
      } else {
        this.isSidebarOpen = false;
      }
    }
  },
  methods: {
    handleSidebarToggle() {
      this.isSidebarOpen = !this.isSidebarOpen;
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.isSidebarOpen = true;
    }
  }
};
</script>

<style scoped>
#app {
  display: flex;
  height: 100vh;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease-in-out;
  margin-left: 0;
  overflow-y: auto;
}

.sidebar-open {
  margin-left: 170px;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }
  .sidebar-open {
    margin-left: 0;
  }
}
</style>
